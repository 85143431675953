.octocatLink {
  margin: 0 0.4em;
  color: var(--colors-text) !important;
}

.octocatLink:hover,
.octocatLink:focus-visible {
  color: var(--colors-link) !important;
}

.octocat {
  display: inline;
  width: 1.2em;
  height: 1.2em;
  vertical-align: -0.2em;
}
